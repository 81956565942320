import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import APISearch from 'components/Search/APISearch';
import APIGlossar from 'components/Search/APISearchGlossar';
import NavButtons from 'components/Advanced_User/INSTAR_MQTT_Broker/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR MQTT v5 Server for INSTAR WQHD Cameras",
  "path": "/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/",
  "dateChanged": "2022-10-08",
  "author": "Mike Polinowski",
  "excerpt": "MQTT (Message Queuing Telemetry Transport) ist ein Machine-to-Machine (M2M)/ Internet of Things (IoT) Verbindungsprotokoll. Es wurde als ressourcenschonender Publish/Subscribe Messaging-Transport konzipiert. Es ist nützlich für Verbindungen wo ein geringer Speicherbedarf erforderlich ist und/oder die Netzwerkbandbreite begrenzt ist. Alle Full-HD-Kameras verfügen jetzt über einen internen MQTT-Broker: IN-9408 2k+.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/AU_SearchThumb_MQTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_MQTT_white.webp",
  "category": "smarthome",
  "type": "Windows"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='INSTAR MQTT v5 Server for INSTAR WQHD Cameras' dateChanged='2019-08-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='MQTT (Message Queuing Telemetry Transport) ist ein Machine-to-Machine (M2M)/ Internet of Things (IoT) Verbindungsprotokoll. Es wurde als ressourcenschonender Publish/Subscribe Messaging-Transport konzipiert. Es ist nützlich für Verbindungen wo ein geringer Speicherbedarf erforderlich ist und/oder die Netzwerkbandbreite begrenzt ist. Alle Full-HD-Kameras verfügen jetzt über einen internen MQTT-Broker: IN-9408 2k+.' image='/images/Search/AU_SearchThumb_MQTT.png' twitter='/images/Search/AU_SearchThumb_MQTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/INSTAR_MQTT_Broker/MQTTv5_API/' locationFR='/fr/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/' crumbLabel="API" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#mqtt-topics-and-message-payloads"
        }}>{`MQTT Topics and Message Payloads`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#example-application-node-red-flows"
        }}>{`Example Application Node-RED Flows:`}</a></li>
    </ul>
    <h1 {...{
      "id": "instar-mqtt-v5-broker-2k-wqhd-serie",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-mqtt-v5-broker-2k-wqhd-serie",
        "aria-label": "instar mqtt v5 broker 2k wqhd serie permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR MQTT v5 Broker (2k+ WQHD Serie)`}</h1>
    <h2 {...{
      "id": "api-documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#api-documentation",
        "aria-label": "api documentation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`API Documentation`}</h2>
    <h3 {...{
      "id": "http-and-mqtt-interface-search",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#http-and-mqtt-interface-search",
        "aria-label": "http and mqtt interface search permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HTTP and MQTT Interface Search`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <APIGlossar mdxType="APIGlossar" />
    <EuiSpacer mdxType="EuiSpacer" />
    <APISearch mdxType="APISearch" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ul>
      <li parentName="ul"><strong parentName="li">{`See also`}</strong>{`: `}<a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/WQHD_Advanced_PTZ_Commands/"
        }}>{`Limitations for the extended PTZ command set`}</a>{`:`}</li>
    </ul>
    <h2 {...{
      "id": "example-application-node-red-flows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#example-application-node-red-flows",
        "aria-label": "example application node red flows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example Application (Node-RED Flows):`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cdc2a229f29008547299d2bc7919b473/0d1a4/MQTTv5_Multimedia_Menu_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABkklEQVQoz1VR224TQQzdvwlB4qXqZ6aVoH+FQFy6bVWERARp2t1krzOe8fiKtqECrPNgS+fIPsfV2bvb86u786u7s7e360292tSvNvX6hMt6dXGzXlC/ubx5fVGvNs/jC6HCQgGpqJ+KRMX+9J6soIiKux+BkI2YRV+o7tUwjMMU2LyIsXlGdNMuSRPJJ4UxQU7u3kS6P+Cu7SOAmLMaq1UjYEQWMxJj9ZRSIZpQfw64nQrmzAW/d+UYCUh3zXGYoYgjG7JVAXIpBGQffgVRKxm+NeG+zSwyJ4khYE7XT/lhWPYfu6GfgpiTLMuqbgzNCG2U6310FSb88hBGKCZsRjknKotbFgGAY9f/J26mtJ+KqraR3BfPInLK43PYtqEVXhIys5TSoesPw/z3bIiBCLP4xx2MWSjD7X5aghXbjTyMEyCSWuElkQhphkRqJ1SI+akPX/fQQ+mhOJf32/HHEdxYVQJyEtPnX6h7gBT+FUOMIcTHmerH6KaE+dNuHoBMRUQy0hxjQVy+ntIcU8iUWU/4DVgtJ2ndDB6kAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cdc2a229f29008547299d2bc7919b473/e4706/MQTTv5_Multimedia_Menu_01.avif 230w", "/en/static/cdc2a229f29008547299d2bc7919b473/d1af7/MQTTv5_Multimedia_Menu_01.avif 460w", "/en/static/cdc2a229f29008547299d2bc7919b473/7f308/MQTTv5_Multimedia_Menu_01.avif 920w", "/en/static/cdc2a229f29008547299d2bc7919b473/97bad/MQTTv5_Multimedia_Menu_01.avif 1036w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cdc2a229f29008547299d2bc7919b473/a0b58/MQTTv5_Multimedia_Menu_01.webp 230w", "/en/static/cdc2a229f29008547299d2bc7919b473/bc10c/MQTTv5_Multimedia_Menu_01.webp 460w", "/en/static/cdc2a229f29008547299d2bc7919b473/966d8/MQTTv5_Multimedia_Menu_01.webp 920w", "/en/static/cdc2a229f29008547299d2bc7919b473/fcad6/MQTTv5_Multimedia_Menu_01.webp 1036w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cdc2a229f29008547299d2bc7919b473/81c8e/MQTTv5_Multimedia_Menu_01.png 230w", "/en/static/cdc2a229f29008547299d2bc7919b473/08a84/MQTTv5_Multimedia_Menu_01.png 460w", "/en/static/cdc2a229f29008547299d2bc7919b473/c0255/MQTTv5_Multimedia_Menu_01.png 920w", "/en/static/cdc2a229f29008547299d2bc7919b473/0d1a4/MQTTv5_Multimedia_Menu_01.png 1036w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cdc2a229f29008547299d2bc7919b473/c0255/MQTTv5_Multimedia_Menu_01.png",
              "alt": "INSTAR MQTTv5 Multimedia Menu",
              "title": "INSTAR MQTTv5 Multimedia Menu",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://wiki.instar.com/en/Node-RED_Flows/MQTTv5_Multimedia_Menu.json"
      }}>{`Network Menu`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/99c973a862e7623193fa7cc7a4c5f072/5819f/MQTTv5_Network_Menu_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.521739130434778%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAzklEQVQY0z2PzU7DMBCE/YIgFdq+H03gSXgBzo3hgERplcTYOP7b3TEK4I728M3MZVbtn97We3zd9cN9N9we9F2nt93Kv3bYNN72+uZBbw7/dtdrxZSZqDYx88lmAC1oAFQwhK8VAPVxHi+zDQUu8XeWsPhnbeaFhKWQ+BB9iCRIhSbztYRYWBi1sBzHqGzixBABMUiQg395tzZSXTM457z3f4ustbMx4zSnUgTVZ1Y5peuSk8tLTMTCIkTknKP2EYBPV87jfJlMzFQYLPgBdLoNmG7NmecAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/99c973a862e7623193fa7cc7a4c5f072/e4706/MQTTv5_Network_Menu_01.avif 230w", "/en/static/99c973a862e7623193fa7cc7a4c5f072/d1af7/MQTTv5_Network_Menu_01.avif 460w", "/en/static/99c973a862e7623193fa7cc7a4c5f072/7f308/MQTTv5_Network_Menu_01.avif 920w", "/en/static/99c973a862e7623193fa7cc7a4c5f072/10a28/MQTTv5_Network_Menu_01.avif 1042w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/99c973a862e7623193fa7cc7a4c5f072/a0b58/MQTTv5_Network_Menu_01.webp 230w", "/en/static/99c973a862e7623193fa7cc7a4c5f072/bc10c/MQTTv5_Network_Menu_01.webp 460w", "/en/static/99c973a862e7623193fa7cc7a4c5f072/966d8/MQTTv5_Network_Menu_01.webp 920w", "/en/static/99c973a862e7623193fa7cc7a4c5f072/20e4c/MQTTv5_Network_Menu_01.webp 1042w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/99c973a862e7623193fa7cc7a4c5f072/81c8e/MQTTv5_Network_Menu_01.png 230w", "/en/static/99c973a862e7623193fa7cc7a4c5f072/08a84/MQTTv5_Network_Menu_01.png 460w", "/en/static/99c973a862e7623193fa7cc7a4c5f072/c0255/MQTTv5_Network_Menu_01.png 920w", "/en/static/99c973a862e7623193fa7cc7a4c5f072/5819f/MQTTv5_Network_Menu_01.png 1042w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/99c973a862e7623193fa7cc7a4c5f072/c0255/MQTTv5_Network_Menu_01.png",
              "alt": "INSTAR MQTTv5 Network Menu",
              "title": "INSTAR MQTTv5 Network Menu",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://wiki.instar.com/en/Node-RED_Flows/MQTTv5_Network_Menu.json"
      }}>{`Network Menu`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ce8cdca0cd02d93fd3b7a3d9de85588b/77abb/MQTTv5_Alarm_Menu_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACdElEQVQozzVRu2pVURA9PyAo+AUmxkYEC39ABAuJtSJEC1HRztLK0tzEoKWNCPGBqOgPCBYKkkKM95poHjc3V3Nf57Vfs58ze8uJOkwzzKyZNWtlJx6snXy4dnzp+9R8e7rVnmp1plqdowudY4vtI/PtqVZ7ZqEzs9A5Mv9tv9uURxeayelWOyvBF4A2RLefufJj4XPpRsLFmCoIE+Em0seYbIjMhCF33GKgaDFmeZ6DNkjkfAgBNSgpOOdyZVeWEILTBZffBjDkNqVI3qx0y41cp0SEmJXSGE/pfwCAAoiE3IRhDdZoZw14+rqn+rWNwY5qVUFIkcbSZT+7v/ZG+XZ3e/H+0vLyU79PIKVEiIIzo/WoZOsTbTxxg0aD1saG5DFtli77+OH9u9cvb12/evjggbNnTjvrYowpJURknFutNvbKz7ui4RxJKSWEoJh6tVsdQLb05M21O/fmbt89NHPq/OWbzWajm8tESkpvbcFkZ6gKaRHRW5szlStUjtbHOrvyavfC850bb3fPPVq99XqjZEID/AUXTAJALWS3ct3SpBSd0d1RvTqAhghRZqyL6AoB3GBKiQvBGPPexxgHNYCS24PyU080j1C0WvUn/MvvBoxImWlq9nmHce0QUQEIIY0xRCSEQGdWetWv2iZqjHTWTGpZKr82AuNChlav9qvNwuwLTAaUVsLbBiz/gVmuPCLGSOhtb1zvlAZcsB4zzjkXAimGEBjnvXG1V0EuXa7CRDohpRS8X8itXAekmkvG+UR5pPh1ANlf9RVAIuyPq7kXW7OPf1x6vjH7+MfFZ5sFk423Hj3SVmGHlTRadUbaOAxIfwDA8LAeYLALHQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce8cdca0cd02d93fd3b7a3d9de85588b/e4706/MQTTv5_Alarm_Menu_01.avif 230w", "/en/static/ce8cdca0cd02d93fd3b7a3d9de85588b/d1af7/MQTTv5_Alarm_Menu_01.avif 460w", "/en/static/ce8cdca0cd02d93fd3b7a3d9de85588b/7f308/MQTTv5_Alarm_Menu_01.avif 920w", "/en/static/ce8cdca0cd02d93fd3b7a3d9de85588b/1537c/MQTTv5_Alarm_Menu_01.avif 1305w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ce8cdca0cd02d93fd3b7a3d9de85588b/a0b58/MQTTv5_Alarm_Menu_01.webp 230w", "/en/static/ce8cdca0cd02d93fd3b7a3d9de85588b/bc10c/MQTTv5_Alarm_Menu_01.webp 460w", "/en/static/ce8cdca0cd02d93fd3b7a3d9de85588b/966d8/MQTTv5_Alarm_Menu_01.webp 920w", "/en/static/ce8cdca0cd02d93fd3b7a3d9de85588b/5b044/MQTTv5_Alarm_Menu_01.webp 1305w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce8cdca0cd02d93fd3b7a3d9de85588b/81c8e/MQTTv5_Alarm_Menu_01.png 230w", "/en/static/ce8cdca0cd02d93fd3b7a3d9de85588b/08a84/MQTTv5_Alarm_Menu_01.png 460w", "/en/static/ce8cdca0cd02d93fd3b7a3d9de85588b/c0255/MQTTv5_Alarm_Menu_01.png 920w", "/en/static/ce8cdca0cd02d93fd3b7a3d9de85588b/77abb/MQTTv5_Alarm_Menu_01.png 1305w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ce8cdca0cd02d93fd3b7a3d9de85588b/c0255/MQTTv5_Alarm_Menu_01.png",
              "alt": "INSTAR MQTTv5 Multimedia Menu",
              "title": "INSTAR MQTTv5 Multimedia Menu",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://wiki.instar.com/de/Node-RED_Flows/MQTTv5_Alarm_Menu.json"
      }}>{`Alarm Menu`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      